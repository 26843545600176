import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import { Form, Input, Button, Divider, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FiKey } from 'react-icons/fi';

import LayoutHeader from '../blocks/codes/authPageHeader';
import { Firebase } from '../config/firebase';
import ForgotPwd from '../blocks/components/forgotPwd';
import SEO from '../blocks/layout/seo';
import '../styles/auth-page.scss';

// markup
const LoginPage = () => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false);
      const [googleLoading, setGoogleLoading] = useState(false);
      const [, forceUpdate] = useState({});
      
      useEffect(() => {
            if (window.localStorage.getItem('uid') !== null && window.localStorage.getItem('uid') !== undefined && window.localStorage.getItem('uid') !== '') {
                  setLoading(true);
                  navigate('/setup');
            }
            else {
                  forceUpdate({});
                  Firebase.auth().onAuthStateChanged((user) => {
                        if (user) {
                              navigate('/setup');
                        }
                  });
            }

      }, []);

      const loginWithEmail = (values) => {
            const { email, password } = values;
            setLoading(true);


            Firebase.auth()
                  .signInWithEmailAndPassword(email, password)
                  .then((result) => {
                        if (result !== undefined && result.user !== undefined && result.user.uid !== undefined) {
                              window.localStorage.setItem('uid', result.user.uid);
                        }
                        window.localStorage.removeItem('active_project_id');
                        setLoading(false);
                        message.success('Logged-in successful!');
                  })
                  .catch((err) => {
                        setLoading(false);
                        if (err.code === 'auth/user-not-found') {
                              message.info(
                                    'There is no user record corresponding to this identifier. The user may have been deleted.'
                              );
                        } else {
                              message.error(err.message);
                        }
                  });
      };

      const loginWithGoogle = (e) => {
            e.preventDefault();
            setGoogleLoading(true);
            const providerOAuth = new Firebase.auth.GoogleAuthProvider();

            Firebase.auth()
                  .signInWithPopup(providerOAuth)
                  .then((result) => {
                        if (result !== undefined && result.user !== undefined && result.user.uid !== undefined) {
                              window.localStorage.setItem('uid', result.user.uid);
                        }
                        window.localStorage.removeItem('active_project_id');
                        setGoogleLoading(false);
                  })
                  .catch((err) => {
                        setGoogleLoading(false);
                        if (
                              err.code ===
                              'auth/account-exists-with-different-credential'
                        ) {
                              message.info('Try to use email-password login');
                        } else {
                              message.error(err.message);
                        }
                  });
      };

      return (
            <div className="auth-page">
                  <SEO title="Login to dashboard — NoCodeAPI" />
                  <LayoutHeader type="login" />
                  <div className="auth-form">
                        <div className="heading">
                              <h2>Login to dashboard</h2>
                              <Button
                                    size="large"
                                    style={{
                                          padding: '0px 30px',
                                          width: 300,
                                          fontSize: 15,
                                          marginTop: 40,
                                    }}
                                    loading={googleLoading}
                                    onClick={loginWithGoogle}
                              >
                                    <img
                                          src="/google-logo.png"
                                          alt="google login"
                                          height="28px"
                                          width="28px"
                                          style={{
                                                marginRight: 15,
                                          }}
                                    />
                                    <span>Login with Google</span>
                              </Button>
                              <Divider
                                    plain
                                    style={{
                                          margin: '40px 0px',
                                          fontWeight: 300,
                                          fontSize: 13,
                                          color: '#606060',
                                    }}
                              >
                                    or login with email
                              </Divider>
                              <Form
                                    form={form}
                                    name="login_form"
                                    className="auth-input-form"
                                    initialValues={{ remember: true }}
                                    onFinish={loginWithEmail}
                              >
                                    <Form.Item name="email">
                                          <Input
                                                size="large"
                                                prefix={
                                                      <MailOutlined className="site-form-item-icon" />
                                                }
                                                placeholder="Email Address"
                                                required
                                          />
                                    </Form.Item>
                                    <Form.Item name="password">
                                          <Input.Password
                                                prefix={
                                                      <FiKey className="site-form-item-icon" />
                                                }
                                                size="large"
                                                type="password"
                                                placeholder="Password"
                                                required
                                          />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                          {() => (
                                                <Button
                                                      type="primary"
                                                      size="large"
                                                      htmlType="submit"
                                                      loading={loading}
                                                      className="auth-btn auth-form-button"
                                                >
                                                      Login now
                                                </Button>
                                          )}
                                    </Form.Item>
                              </Form>

                              <ForgotPwd />
                        </div>
                  </div>
            </div>
      );
};

export default LoginPage;
