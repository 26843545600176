import React from 'react';
import { Link } from 'gatsby';

const LayoutHeader = ({ type }) => {
      return (
            <div className="auth-page-header">
                  <div className="logo">
                        <img src="/logo.png" alt="NoCodeAPI Logo" height="20" />
                        <span>NoCodeAPI</span>
                  </div>
                  {type !== 'setup' && (
                        <Link
                              to={type === 'login' ? '/signup' : '/login'}
                              className="nav-link"
                        >
                              {type === 'login'
                                    ? 'Don’t have an account?'
                                    : 'I already have an account —'}{' '}
                              <b>
                                    <u>
                                          {type === 'login'
                                                ? 'Create new account'
                                                : 'Sign in'}
                                    </u>
                              </b>
                        </Link>
                  )}
            </div>
      );
};

export default LayoutHeader;
